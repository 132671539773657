import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PlaceholderImg from '../assets/images/default.jpg'
import SEO from '../components/seo'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class BlogIndex extends React.Component {
  constructor() {
    super()
    this.state = {
      value: 'All',
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(event) {
    this.setState({
      value: event.target.value,
    })
  }

  render() {
    const { data } = this.props
    // console.log('data ni', data)
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allSanityPost.edges
    const categoriesPost =
      this.state.value === 'All'
        ? posts.map(val => val.node)
        : posts
            .map(val => val.node)
            .filter(
              post =>
                post.categories.length !== 0 &&
                post.categories[0].title === this.state.value
            )

    const catOnly = data.allSanityPost.group

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />

        <div id="blog-hero-section" className="blog-hero-section text-center">
          <h1>Recent Articles</h1>
        </div>
        <div className="container custom-container my-5">
          <div className="select-wrap d-none">
            <h5 className="mt-2">Select Category</h5>
            <select value={this.state.value} onChange={this.handleChange}>
              <option value="All">All</option>
              {catOnly.map((cat, index) => (
                <option key={index} value={cat.fieldValue}>
                  {cat.fieldValue}
                </option>
              ))}
            </select>
          </div>
          <div className="category-wrap row">
            <div className="blogs col-md-9 col-12 pr-5">
              {categoriesPost.map(post => (
                <div key={post.id} className="blog-item row my-5">
                  <div className="blog-image col-sm-4">
                    {(post.mainImage && (
                      <LazyLoadImage
                        src={post.mainImage.asset.url}
                        alt="blogPhoto"
                        className="img-fluid"
                      />
                    )) || (
                      <LazyLoadImage
                        className="img-fluid"
                        src={PlaceholderImg}
                        alt="default"
                      />
                    )}
                  </div>
                  <div
                    key={post.slug.current}
                    className="blog-text col-sm-8"
                    height="100%"
                  >
                    <h4
                      className="mt-0"
                      style={{
                        letterSpacing: '1.5px',
                      }}
                    >
                      <Link to={`/${post.slug.current}`}>{post.title}</Link>
                    </h4>
                    <small
                      className="ml-1 font-italic"
                      style={{ color: `#d86327` }}
                    >
                      <span className="text-dark">Posted in: </span>
                      {post.categories.length !== 0
                        ? post.categories[0].title
                        : 'Uncategorized'}
                      <span className="text-dark">, By: </span>
                      {(post.author && post.author.name) || 'not specified'}
                      <span className="text-dark">, on: </span>
                      {(post.publishedAt &&
                        moment(post.publishedAt).format('MMMM Do, YYYY')) ||
                        moment(post._createdAt).format('MMMM Do, YYYY')}
                    </small>

                    <p className="excerpt mt-3" style={{ lineHeight: `24px` }}>
                      {post.excerpt}..
                    </p>
                    <Link to={`/${post.slug.current}`}>
                      <i
                        className="fa fa-chevron-right mr-2"
                        aria-hidden="true"
                      />
                      Continue Reading
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-3 col-12 category-side-pos mt-4">
              <h4 className="mt-0">Categories</h4>
              <ul className="sidebar-blog-cat mt-3">
                <li className="py-2">
                  <i
                    style={{ color: '#d86327' }}
                    className="fa fa-check fa-sm"
                  />
                  <input
                    style={{
                      background: 'transparent',
                      border: 0,
                      // fontWeight: 'bold',
                      color: '#d86327',
                      cursor: 'pointer',
                      outline: 'none',
                    }}
                    type="button"
                    onClick={this.handleChange}
                    value="All"
                  />
                </li>
                {catOnly.map((cat, index) => (
                  <li key={index} className="py-2">
                    <i
                      style={{ color: '#d86327' }}
                      className="fa fa-check fa-sm"
                    />
                    <input
                      style={{
                        background: 'transparent',
                        border: 0,
                        // fontWeight: 'bold',
                        color: '#d86327',
                        cursor: 'pointer',
                        outline: 'none',
                      }}
                      type="button"
                      onClick={this.handleChange}
                      value={cat.fieldValue}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSanityPost(
      filter: { status: { eq: "published" } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          excerpt
          slug {
            current
          }
          categories {
            id
            title
          }
          publishedAt
          _createdAt
          mainImage {
            asset {
              url
            }
          }
          author {
            id
            name
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
